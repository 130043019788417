import { makeStyles } from '@material-ui/styles'

const appStyles = makeStyles((theme) => ({
  appContainer: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 4,
    marginRight: 4,
    [theme.breakpoints.down('sm')]: {
      marginTop: 3.5,
      marginBottom: 3.5,
      marginLeft: 6,
      marginRight: 6,
    },
  },
  appContainerBody: {
    display: 'flex',
    flexDirection: 'row',
  },
  appInnerContainer: {
    height: 80,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 70,
    },
  },
  appInnerContainerBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appContainerActionArea: {
    height: 88,
    [theme.breakpoints.down('sm')]: {
      height: 70,
    },
  },
  iconContainer: {
    height: 88,
    width: 88,
    [theme.breakpoints.down('sm')]: {
      height: 70,
      width: 70,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 88,
    width: 88,
    [theme.breakpoints.down('sm')]: {
      height: 70,
      width: 70,
    },
  },
  textContainer: {
    width: '65%',
  },
  appName: {
    marginLeft: '8px',
    height: '25px',
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
    },
  },
  alignLeft: {
    textAlign: 'left',
  },
  maintenanceFavoriteInfoBannerContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: -4,
    },
  },
  maintenanceFavoriteInfoBannerContainerBody: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  iconButton: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '21px',
    color: theme.palette.base.lightGray,
  },
  iconFocus: {
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
  },
  selectedFavoriteIconButton: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '20px',
    color: theme.color.rutgersRed,
    margin: '0.5px',
  },
  iconButtonBorder: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '20px',
    color: theme.color.rutgersRed,
  },
  tocIcon: {
    color: '#929292',
  },
  appNameText: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: '16px',
  },
  isNewBanner: {
    position: 'absolute',
    top: '15px',
    right: '-22px',
    width: '110px',
    transform: 'rotate(35deg)',
    backgroundColor: theme.palette.base.green,
  },
  favoritesDragHandle: {
    position: 'absolute',
    top: '1px',
    right: '-35px',
    width: '60px',
  },
  isNewText: {
    color: 'black',
    fontSize: '12px',
    fontWeight: '600',
    textShadow: '2px',
    marginLeft: 40,
  },
  maintenanceBanner: {
    position: 'absolute',
    top: '15px',
    right: '-22px',
    width: '110px',
    transform: 'rotate(35deg)',
    backgroundColor: theme.palette.base.yellow,
  },
  maintenanceContainer: {
    position: 'relative',
    right: 17,
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      right: '10%',
      marginTop: 16,
    },
  },
  maintenanceText: {
    color: 'black',
    fontSize: '10px',
    fontWeight: '600',
    textShadow: '2px',
    marginLeft: 20,
  },
  maintenanceInfo: {
    color: theme.palette.base.red,
    textAlign: 'center',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12.7px',
    },
  },
  sessionTimeoutImage: {
    width: 500,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default appStyles
