import { makeStyles } from '@material-ui/core/styles'

export const useNewsModalStyles = makeStyles((theme) => ({
  listIconStyle: {
    color: theme.palette.components.sideNavIcon,
  },
  dialog: {
    minWidth: '500px',
    width: '70vw',
    maxWidth: '100%',
    height: '70vh',
    maxHeight: '600px',
    padding: '0px',
    backgroundColor: theme.palette.paper.secondary,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      width: '90vw',
    },
  },
  rootTabContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.backgroundColor.secondary,
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBarIcon: {
    color: theme.palette.components.topBarIcon,
    fontSize: '24px',
  },
}))

export default useNewsModalStyles
