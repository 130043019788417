import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Box } from '@material-ui/core'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import { PolicyStyles } from './policyStyles'
import WidgetFooterButton from './WidgetFooterButton'
import Widget from '../Widget'
import SearchBar from '../../DashboardComponents/SearchBar/SearchBar'
import {
  a11yProps,
  TabPanel,
} from '../../DashboardComponents/TabPanel/TabPanel'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import { fetchPolicyItems, selectPolicyData } from './policySlice'
import ItemList from './ItemList'
import { serverDataParams, uiTextContent } from './data/policyParams'
import { logDashboardEvent } from '../../events'

const Policy = ({
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
  widgetId,
}) => {
  const widgetData = useSelector(selectPolicyData)
  const widgetCallState = useSelector((state) => state.policy.status)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const user = localStorage.getItem('user') ?? 'visitor'
    const isGuest = user === '"visitor"' || user === ''
    // not sure if we need isGuest for this might come into picture when voting is included
    dispatch(fetchPolicyItems(isGuest))
  }, [])

  return (
    <Widget>
      <Widget.Header title={title} />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderPolicyState(widgetCallState, widgetData, widgetId, title)}
      </Widget.Content>
    </Widget>
  )
}

function renderPolicyState(widgetCallState, widgetData, widgetId, title) {
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded widgetData={widgetData} widgetId={widgetId} title={title} />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded({ widgetData, widgetId, title }) {
  const policyLibUrl = serverDataParams.POLICY_LIBRARY_URL

  const classes = PolicyStyles()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = React.useState(0)
  const [searchQuery, setSearchQuery] = React.useState('')
  const handleTabChange = () => {
    if (currentTab) {
      setCurrentTab(0)
    } else {
      setCurrentTab(1)
    }
  }
  const handleSearchQuery = (searchTerm) => {
    const redirectString = searchTerm.toString().split(' ').join('%20')
    window.open(
      `https://policies.rutgers.edu/IndexSearchPublic.aspx?Search=${redirectString}`,
      '_blank',
    )
  }

  const handleClick = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        title,
        policyLibUrl,
      )
      window.open(policyLibUrl)
    }
  }
  return (
    <>
      <Box className={classes.searchBarContainer}>
        <SearchBar
          placeholder={uiTextContent.SEARCHBAR_PLACHOLDER}
          className={classes.searchBar}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onCancelSearch={() => {
            setSearchQuery('')
          }}
          webViewMode
          onRequestSearch={() => handleSearchQuery(searchQuery)}
          variant="outlined"
        />
      </Box>
      <Box className={classes.modalTabs}>
        <RutgersTabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="Top Tab"
          className={classes.tabBackground}
        >
          <RutgersTab
            className={classes.tabText}
            label={uiTextContent.TAB1_LABEL}
            {...a11yProps(0)}
          />
          <RutgersTab
            label={uiTextContent.TAB2_LABEL}
            className={classes.tabText}
            {...a11yProps(1)}
          />
        </RutgersTabs>
      </Box>
      <Box>
        <TabPanel value={currentTab} index={0} className={classes.tabPanel0}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.announcementList}>
              <ItemList
                data={widgetData}
                dataSelect={serverDataParams.NEW_ITEM}
              />
            </div>
          </Grid>
        </TabPanel>
        <TabPanel value={currentTab} index={1} className={classes.tabPanel1}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.announcementList}>
              <ItemList
                dispatch={dispatch}
                widgetId={widgetId}
                data={widgetData}
                dataSelect={serverDataParams.UPDATED_ITEM}
              />
            </div>
          </Grid>
        </TabPanel>
      </Box>
      <WidgetFooterButton
        title={uiTextContent.MAIN_BUTTON_TEXT}
        handleClick={handleClick}
      />
    </>
  )
}

export default Policy
