/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { Typography as Text } from '@material-ui/core'
import ItemCard from './ItemCard'
import PolicyStyles from './policyStyles'
import { uiTextContent } from './data/policyParams'

// const announcementMockup = [
//   {
//     title: 'Announcement 1',
//     text: 'This is the first announcement text body for testing purpouses and such things that may or may not occour',
//     url: 'https//www.rutgers.edu',
//     voteLink: 'https//www.rutgers.edu',
//     reserved: 'for futger development',
//   },
//   {
//     title: 'Announcement 2',
//     text: 'This is the second announcement text body for testing purpouses and such things that may or may not occour',
//     url: 'https//www.rutgers.edu',
//     voteLink: 'https//www.rutgers.edu',
//     reserved: 'for futger development',
//   },
//   {
//     title: 'Announcement 3',
//     text: 'This is the third announcement text body for testing purpouses and such things that may or may not occour',
//     url: 'https//www.rutgers.edu',
//     voteLink: 'https//www.rutgers.edu',
//     reserved: 'for futger development',
//   },
//   {
//     title: 'Announcement 4',
//     text: 'This is the fourth announcement text body for testing purpouses and such things that may or may not occour',
//     url: 'https//www.rutgers.edu',
//     voteLink: 'https//www.rutgers.edu',
//     reserved: 'for futger development',
//   },
// ]
/**
 * Announcement Object shows announcements in the Faculty/Staff Service Center widget
 * @param announcement the announcement object with title and link traits
 */

function ItemList({ dispatch, widgetId, data, dataSelect }) {
  const classes = PolicyStyles()

  return (
    <>
      {data.length === 0 ? (
        <Text className={classes.noCaseText}>
          {uiTextContent.NO_ANNOUNCEMENTS}
        </Text>
      ) : (
        <>
          {data.map(
            (announcement) =>
              announcement[dataSelect] && (
                <ItemCard
                  item={announcement}
                  widgetId={widgetId}
                  dispatch={dispatch}
                />
              ),
          )}
        </>
      )}
    </>
  )
}

export default ItemList
