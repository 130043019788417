import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icon from '@material-ui/core/Icon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)

function IconPicker(params) {
  const missingIconsList = {
    'sign-out': '',
    'mortar-board': '',
    gears: '',
    bank: '',
    'life-saver': '',
    'fa icon-new-one-source': '',
    'fa icon-video-camera': '',
  }
  let icon = params.iconName
  if (icon?.includes('icon-cs-financial')) {
    return <CustomIcon imgSrc="./assets/images/svgs/cs-financial.svg" />
  }
  if (icon?.includes('fa icon-new-one-source')) {
    return <CustomIcon imgSrc="./assets/images/svgs/one-source.svg" />
  }
  if (icon?.includes('icon-cs-hr')) {
    return <CustomIcon imgSrc="./assets/images/svgs/cs-hr.svg" />
  }
  if (icon?.includes('icon-cs-procurement')) {
    return <CustomIcon imgSrc="./assets/images/svgs/cs-financial.svg" />
  }
  if (icon?.includes('icon-cs-budget')) {
    return <CustomIcon imgSrc="./assets/images/svgs/cs-budget.svg" />
  }
  if (icon?.includes('icon-user-tie') || icon?.includes('SUCCESS')) {
    icon = ['fas', 'user-tie']
  }
  if (icon?.includes('fa fa-hands-heart')) {
    icon = ['fas', 'hand-holding-heart']
  }
  if (icon?.includes('icon-bubbles3')) {
    icon = ['fas', 'soap']
  }
  if (icon?.includes('icon-aid-kit')) {
    icon = ['fas', 'first-aid']
  }
  if (icon?.includes('icon-stack')) {
    icon = ['fas', 'layer-group']
  }
  if (icon?.includes('paint')) {
    icon = ['fas', 'paint-brush']
  }
  if (icon?.includes('presentation')) {
    icon = ['fas', 'file-powerpoint']
  }
  if (icon?.includes('mail')) {
    icon = ['fas', 'envelope']
  }
  if (icon?.includes('linkedin')) {
    icon = ['fab', 'linkedin']
  }
  if (icon?.includes('microsoft')) {
    icon = ['fab', 'microsoft']
  }
  if (icon?.includes('house')) {
    icon = ['fas', 'home']
  }
  //   if (icon?.includes('chart')) {
  //     icon = ['fas', 'chart-area']
  //   }
  if (icon?.includes('parking')) {
    icon = ['fas', 'parking']
  }
  if (icon?.includes('youtube')) {
    icon = ['fab', 'youtube']
  }
  if (icon?.includes('fa fa-twitter')) {
    icon = ['fab', 'twitter']
  }
  if (icon?.includes('fas fa-clock')) {
    icon = ['fas', 'clock']
  }
  if (icon?.includes('fas icon-spoon-knife')) {
    icon = ['fas', 'utensils']
  }
  if (icon?.includes('fas house')) {
    icon = ['fas', 'home']
  }
  if (icon?.includes('fas fa-question')) {
    icon = ['fas', 'question']
  }
  if (icon?.includes('fas truck')) {
    icon = ['fas', 'truck']
  }
  if (icon?.includes('fas chair')) {
    icon = ['fas', 'chair']
  }
  if (icon?.includes('fa fa-instagram')) {
    icon = ['fab', 'instagram-square']
  }
  if (icon?.includes('app')) {
    icon = ['fas', 'mobile-alt']
  }
  if (icon?.includes('money')) {
    icon = ['fas', 'money-bill']
  }
  if (icon?.includes('graduation')) {
    icon = ['fas', 'graduation-cap']
  }
  if (icon?.includes('browser')) {
    icon = ['far', 'window-restore']
  }
  if (icon?.includes('shield-checkmark')) {
    return <CustomIcon imgSrc="./assets/images/svgs/shield-checkmark.svg" />
  }
  if (icon?.includes('uwill')) {
    return (
      <CustomIcon
        imgSrc="./assets/images/svgs/uwill.svg"
        size={params.customSize}
      />
    )
  }
  if (icon?.includes('togetherall')) {
    return (
      <CustomIcon
        imgSrc="./assets/images/svgs/togetherall.svg"
        size={params.customSize}
      />
    )
  }
  if (icon?.includes('map-light')) {
    return (
      <CustomIcon
        imgSrc="./assets/images/svgs/map-icon.svg"
        size={params.size}
      />
    )
  }
  if (icon?.includes('map-dark')) {
    return <CustomIcon imgSrc="./assets/images/svgs/knight.svg" />
  }
  if (icon?.includes('map-selected')) {
    return (
      <CustomIcon
        imgSrc="./assets/images/svgs/map-icon-selected.svg"
        size={params.size}
      />
    )
  }
  if (icon?.includes('icon-knight')) {
    return <CustomIcon imgSrc="./assets/images/svgs/knight.svg" />
  }
  if (icon?.includes('icon-oncore')) {
    return <CustomIcon imgSrc="./assets/images/svgs/oncore.svg" />
  }
  if (icon?.includes('fa icon-canvas')) {
    return <CustomIcon imgSrc="./assets/images/svgs/canvas.svg" />
  }
  if (icon?.includes('icon-ru-250')) {
    return <CustomIcon imgSrc="./assets/images/svgs/ru-250.svg" />
  }
  if (icon?.includes('icon-ru')) {
    return (
      <CustomIcon
        imgSrc="./assets/images/svgs/icon_ru.svg"
        size={params.customSize}
      />
    )
  }
  if (icon?.includes('rutgerscareerservicesicon')) {
    return (
      <CustomIcon imgSrc="./assets/images/myApps/Handshake-Home-Page-Button.png" />
    )
  }
  if (icon?.includes('DGLSRES')) {
    return <CustomIcon imgSrc="./assets/images/svgs/douglass.svg" />
  }
  if (icon?.includes('cr-get-involved')) {
    return (
      <CustomIcon
        imgSrc="./assets/images/svgs/get-involved.svg"
        size={params.size}
      />
    )
  }
  if (icon?.includes('cr-logo')) {
    return (
      <CustomIcon
        imgSrc="./assets/images/svgs/cr-logo2.svg"
        size={params.size}
      />
    )
  }

  if (EnrollmentIcons[icon]) {
    icon = EnrollmentIcons[icon]
  }

  if (icon?.includes('fa fa-')) {
    icon = icon?.replace('fa fa-', '')
    if (icon?.slice(icon?.length - 2).includes('-o')) {
      // remove the last two characters from string
      const newIcon = icon?.slice(0, icon?.length - 2)
      icon = newIcon
    }
    if (icon === 'dashboard') {
      icon = 'tachometer-alt'
    } else if (icon === 'credit-card-alt') {
      icon = 'credit-card'
    }
  }

  if (icon?.includes('fab fa-', '')) {
    // brands need to be passed as an array ["prefix", "iconname"]
    icon = icon?.replace('fab fa-', '')
    if (icon?.slice(icon?.length - 2).includes('-o')) {
      const newIcon = icon?.slice(0, icon?.length - 2)
      icon = newIcon
    }
    icon = ['fab', icon]
  }
  if (icon?.includes('far fa-')) {
    icon = icon?.replace('far fa-', '')
    if (icon?.slice(icon?.length - 2).includes('-o')) {
      // remove the last two characters from string
      const newIcon = icon?.slice(0, icon?.length - 2)
      icon = newIcon
    }
  }

  // END ENROLLMENTPATHWAY ICONS
  let iconToReturn = null
  if (icon in missingIconsList) {
    iconToReturn = (
      <FontAwesomeIcon
        icon={['fas', 'question']}
        color={params.color}
        size={params.size}
      />
    )
  } else {
    iconToReturn = (
      <FontAwesomeIcon icon={icon} color={params.color} size={params.size} />
    )
  }

  return iconToReturn
}

export function CustomIcon({ imgSrc, size = 60 }) {
  return (
    <Icon
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: size,
      }}
    >
      <img src={imgSrc} alt="icon" height={size} width={size} />
    </Icon>
  )
}
export default IconPicker

const EnrollmentIcons = {
  fileAlt: ['fas', 'file-alt'],
  dollarSign: ['fas', 'dollar-sign'],
  'far fa-check-circle': ['fas', 'check-circle'],
  'fas fa-book-open': ['fas', 'fa-book-open'],
  'fa fa-chart-line': ['fa', 'fa-chart-line'],
  'icon-books': ['fas', 'book'],
  'fas fa-book': ['fas', 'book'],
  'fa wb-book': ['fas', 'book'],
  'fas fa-undo': ['fas', 'undo'],
  'fa fa-facebook': ['fab', 'facebook-square'],
  'fas fa-lock-open': ['fas', 'lock-open'],
  'fas fa-lock': ['fas', 'lock'],
  'far fa-address-book': ['far', 'address-book'],
  'fas fa-user-tag': ['fas', 'user-tag'],
  'fas fa-map-marked-alt': ['fas', 'map-marked-alt'],
  'fas fa-key': ['fas', 'key'],
  'fas fa-mobile': ['fas', 'mobile'],
  'fas fa-id-card': ['fas', 'id-card'],
  'fas fa-plus': ['fas', 'plus'],
  'fas fa-spa': ['fas', 'spa'],
  'fas fa-running': ['fas', 'running'],
  'fas fa-tools': ['fas', 'tools'],
  'fas fa-people-arrows': ['fas', 'people-arrows'],
  'fas fa-list': ['fas', 'list'],
  'fas fa-suitcase': ['fas', 'suitcase'],
  'fas fa-question': ['fas', 'question'],
  'fa-hospital': ['fas', 'hospital'],
  solidHospital: ['fas', 'hospital'],
  'fa-comment-dollar': ['fas', 'comment-dollar'],
  'fa-user-graduate': ['fas', 'user-graduate'],
  'fa-search': ['fas', 'search'],
  exchangeAlt: ['fas', 'file-alt'],
  'fa-exchange': ['fas', 'file-alt'],
  'fa-wallet': ['fas', 'wallet'],
  'fa-dollar': ['fas', 'dollar-sign'],
  'icon-library': ['fas', 'book-open'],
  'fa wb-library': ['fas', 'book-open'],
  'icon-cogs': ['fas', 'cogs'],
  'fa fa-files-o': ['fas', 'file-alt'],
  'fas fa-file': ['fas', 'file-alt'],
  'icon-book': ['fas', 'book'],
  'fa-university': ['fas', 'university'],
  'icon-calendar': ['fas', 'calendar-alt'],
  'far fa-calendar': ['fas', 'calendar-alt'],
  'files-empty': ['fas', 'file'],
  'fa-clipboard': ['fa', 'clipboard'],
  'icon-paragraph-center': ['fas', 'paragraph'],
  'fa fa-sign-in': ['fas', 'sign-in-alt'],
  'icon-profile': ['fas', 'user-circle'],
  'icon-blocked': ['fas', 'cube'],
  'icon-shield': ['fas', 'shield-alt'],
  'icon-calculator': ['fas', 'calculator'],
  'icon-users': ['fas', 'users'],
  LGBTQRES: ['fas', 'users'],
  PARENTINFO: ['fas', 'users'],
  CULTRELG: ['fas', 'users'],
  'icon-user': ['fas', 'user'],
  'icon-orcid': ['fab', 'orcid'],
  'fas fa-cloud': ['fas', 'cloud'],
  'fas fa-user-friends': ['fas', 'user-friends'],
  'fa-briefcase-medical': ['fas', 'briefcase-medical'],
  'fas fa-notes-medical': ['fas', 'notes-medical'],
  'fas fa-flask': ['fas', 'flask'],
  'fa icon-video-camera': ['fas', 'fa-video'],
  fileCode: ['far', 'file-code'],
  solidLifeRing: ['far', 'life-ring'],
  signOutAlt: ['fas', 'fa-right-from-bracket'],
  'icon-podcast': ['fas', 'podcast'],
  'wf wf-family-o365 wf-o365-outlooklogo': ['far', 'fa-envelope'],
  'wf wf-family-o365 wf-o365-calendar': ['far', 'calendar-alt'],
  'wf wf-family-o365 wf-o365-cloud': ['fas', 'mail-bulk'],
  'wf wf-family-o365 wf-o365-peoplelogo': ['fas', 'user'],
  'wf wf-family-o365 wf-o365-tasks': ['fas', 'tasks'],
  'fas fa-tasks': ['fas', 'tasks'],
  'wf wf-family-o365 wf-o365-wordlogo': ['far', 'file-word'],
  'wf wf-family-o365 wf-o365-excellogo': ['far', 'file-excel'],
  'wf wf-family-o365 wf-o365-powerpointlogo': ['far', 'file-powerpoint'],
  'wf wf-family-o365 wf-o365-onenotelogo': ['far', 'sticky-note'],
  'icon-mail4': ['fas', 'envelope'],
  'icon-coin-dollar': ['fas', 'envelope'],
  'icon-spell-check': ['fas', 'spell-check'],
  'fas fa-chalkboard-teacher': ['fas', 'chalkboard-teacher'],
  'pencil-square': ['fas', 'pen-square'],
  pencil: ['fas', 'pencil-alt'],
  PENJI: ['fas', 'pencil-alt'],
  'icon-key2': ['fas', 'key'],
  'fas fa-credit-card': ['fas', 'credit-card'],
  'icon-keyboard': ['fas', 'keyboard'],
  'icon-keyboard-regular': ['far', 'keyboard'],
  'file-text': ['far', 'file-alt'],
  'fas fa-hand-holding-medical': ['fas', 'hand-holding-medical'],
  handHoldingMedical: ['fas', 'hand-holding-medical'],
  'fas fa-wave-square': ['fas', 'wave-square'],
  'fas fa-dollar-sign': ['fas', 'dollar-sign'],
  '<i class="fas fa-dollar-sign"></i>': ['fas', 'dollar-sign'],
  'fas fa-handshake': ['far', 'handshake'],
  'icon-accessibility': ['fab', 'accessible-icon'],
  'icon-spoon-knife': ['fas', 'utensils'],
  'icon-signs-post': ['fas', 'signs-post'],
  'icon-meal-plans': ['fas', 'clipboard-list'],
  'icon-map': ['far', 'map'],
  'icon-table2': ['fas', 'table'],
  'icon-display': ['fas', 'desktop'],
  'fa fa-tachometer': ['fas', 'tachometer-alt'],
  'icon-search': ['fas', 'search'],
  'icon icon-paragraph-center': ['fas', 'align-center'],
  'fas fa-comments': ['far', 'comments'],
  'fa fa-comments': ['far', 'comments'],
  'fa fa-arrows': ['fas', 'arrows-alt'],
  'fas fa-hand-holding-heart': ['fas', 'hand-holding-heart'],
  'icon icon-attachment': ['fas', 'paperclip'],
  'icon-attachment': ['fas', 'paperclip'],
  'fas fa-envelope-open': ['fas', 'envelope-open-text'],
  'icon-spinner9': ['fas', 'spinner'],
  'fas fa-spinner': ['fas', 'spinner'],
  'icon-list': ['fas', 'list'],
  'icon-address-book': ['far', 'address-book'],
  'far fa-box-usd': ['fas', 'box'],
  'far fa-box': ['fas', 'box'],
  'icon-clipboard': ['fas', 'clipboard'],
  'icon-user-tie': ['fas', 'user-tie'],
  SUCCESS: ['fas', 'user-tie'],
  'fa fa-hands-heart': ['fas', 'hand-holding-heart'],
  'icon-bubbles3': ['fas', 'soap'],
  'icon-aid-kit': ['fas', 'first-aid'],
  'icon-stack': ['fas', 'layer-group'],
  paint: ['fas', 'paint-brush'],
  presentation: ['fas', 'file-powerpoint'],
  mail: ['fas', 'envelope'],
  linkedin: ['fab', 'linkedin'],
  microsoft: ['fab', 'microsoft'],
  house: ['fas', 'home'],
  chart: ['fas', 'chart-area'],
  youtube: ['fab', 'youtube'],
  'fa fa-twitter': ['fab', 'twitter'],
  'fas fa-clock': ['fas', 'clock'],
  'fa fa-instagram': ['fab', 'instagram-square'],
  app: ['fas', 'mobile-alt'],
  money: ['fas', 'money-bill'],
  truck: ['fas', 'truck-pickup'],
  chair: ['fas', 'chair'],
  graduation: ['fas', 'graduation-cap'],
  graduationCap: ['fas', 'graduation-cap'],
  browser: ['far', 'window-restore'],
  'far fa-sticky-note': ['far', 'fa-sticky-note'],
  'fa fa-circle': ['fa', 'fa-circle'],
  'fa fa-video': ['fa', 'fa-video'],
  'far fa-file-word': ['far', 'fa-file-word'],
  'far fa-file-excel': ['far', 'fa-file-excel'],
  'far fa-file-powerpoint': ['far', 'fa-file-powerpoint'],
  'far fa-newspaper': ['far', 'fa-newspaper'],
  'fab fa-linkedin-in': ['fab', 'linkedin'],
  'fa fa-youtube-play': ['fab', 'fa-youtube'],
  arrowsAltH: ['fas', 'fa-arrows-h'],
  arrowsAlt: ['fas', 'fa-arrows'],
  'icon-pie-chart': ['fas', 'chart-pie'],
  'fa icon-canvas': './assets/images/svgs/canvas.svg',
  'icon-knight': './assets/images/svgs/knight.svg',
  'icon-ru': './assets/images/svgs/icon_ru.svg',
  rutgerscareerservicesicon:
    './assets/images/myApps/Handshake-Home-Page-Button.png',
  'map-dark': './assets/images/svgs/knight.svg',
  'map-selected': './assets/images/svgs/map-icon-selected.svg',
  'map-light': './assets/images/svgs/map-icon.svg',
  'icon-cs-budget': './assets/images/svgs/cs-budget.svg',
  'icon-cs-procurement': './assets/images/svgs/cs-financial.svg',
  'icon-cs-hr': './assets/images/svgs/cs-hr.svg',
  'icon-cs-financial': './assets/images/svgs/cs-financial.svg',
  NEWSTDTORNT: ['fas', 'users'],
  RECNSO: ['fas', 'users'],
  REQISO: ['fas', 'users'],
  REQNSO: ['fas', 'users'],
  DISBSERV: ['fas', 'users'],
  OFCDISBSERV: ['fas', 'users'],
  SOAR: ['fas', 'users'],
  EOF: ['fas', 'users'],
  ATTENDFAIR: ['fas', 'users'],
  SCDONLCR: ['fas', 'users'],
  STDNTRES: ['fas', 'users'],
  NSOCAMDEN: ['fas', 'users'],
  CAMINTERNAT: ['fas', 'users'],
  AUTHUSRACS: ['fas', 'users'],
  IMMUNIZA: ['fas', 'user-md'],
  STDHEALTINSU: ['fas', 'user-md'],
  STDHEALTHCONTENT: ['fas', 'user-md'],
  CAMDISABLE: ['fas', 'user-md'],
  EMERCONTCT: ['far', 'address-book'],
  ONLDIR: ['far', 'address-book'],
  FINLTRSCPTRAN: ['far', 'address-book'],
  CAMUNDOC: ['far', 'address-book'],
  CDSTDCNDCT: ['fas', 'university'],
  TITLEIX: ['fas', 'university'],
  LIBRARYACCOUNT: ['fas', 'university'],
  RUTGERSID: ['far', 'id-card'],
  RUTGERSIDREC: ['far', 'id-card'],
  PLACMNTTEST: ['fas', 'pencil-alt'],
  PLACMNTTRANS: ['fas', 'pencil-alt'],
  SOARPREP: ['fas', 'pencil-alt'],
  VOTE: ['fas', 'pencil-alt'],
  FINLTRSCPT: ['far', 'list-alt'],
  ACDPLNGADV: ['fas', 'chalkboard-teacher'],
  NEWSTDONL: ['fas', 'laptop'],
  HOUSGDING: ['far', 'building'],
  HONORHOUSE: ['far', 'building'],
  CAMHOUSEDINE: ['far', 'building'],
  OFCINTSTD: ['far', 'building'],
  ADVPLCMT: ['far', 'file-alt'],
  OFFICIALTRANSCRIPT: ['far', 'file-alt'],
  WHATSNEXTLETTER: ['far', 'file-alt'],
  UNDOCINFO: ['far', 'file-alt'],
  PRKGTRNSPT: ['fas', 'car'],
  CAMPARK: ['fas', 'car'],
  SUMOPURT: ['fas', 'user'],
  USMLTRYSERV: ['fas', 'user'],
  MYRUN: ['fas', 'user'],
  FYSTDCHKLST: ['fas', 'tasks'],
  MANDCOURSES: ['fas', 'tasks'],
  ADVREGPROGRAM: ['fas', 'tasks'],
  ACDINTG: ['far', 'handshake'],
  HANDSHAKE: ['far', 'handshake'],
  HONORPLEDGE: ['far', 'handshake'],
  VETAFRS: ['fas', 'flag-usa'],
  ADMLTR: ['fas', 'file-alt'],
  GETINVOLVEPROFILE: ['fas', 'file-alt'],
  TRNSUMRPT: ['fas', 'file-alt'],
  TRNSTDCHKLST: ['fas', 'file-alt'],
  IPADADDRCONF: ['far', 'tablet'],
  IPADRCVDCONF: ['far', 'tablet'],
  WORLDLANG: ['fas', 'globe'],
  COVIDVAC: ['fas', 'virus'],
  DUO: ['fas', 'key'],
  KNIGHTCALL: ['fas', 'phone'],
  CHECKMAIL: ['fas', 'mail-bulk'],
  NETIDEMAIL: ['fas', 'mail-bulk'],
  CAMLGBTQ: ['far', 'peace'],
  CAMCULTREL: ['far', 'dove'],
  DIRECTDEPOSIT: ['fas', 'dollar-sign'],
  FINAID: ['fas', 'dollar-sign'],
  ELECDIRDEP: ['fas', 'dollar-sign'],
  TERMBILL: ['fas', 'dollar-sign'],
  RETAKEMATH: ['fas', 'calculator'],
  'check-circle': ['fas', 'check-circle'],
  'times-circle': ['fas', 'times-circle'],
  'external-link-alt': ['fas', 'external-link-alt'],
  park: ['fas', 'tree'],
  circle: ['fas', 'circle'],
  MYRMOBILE: ['fas', 'fa-mobile-screen'],
  CAMAPP: ['fas', 'fa-tablet'],
  CAMMILVET: ['fas', 'fa-tablet'],
  MYGUEOPT: ['fas', 'external-link-alt'],
  REWFEPPOL: ['fas', 'globe'],
  SOCJUST: ['fas', 'fa-scale-balanced'],
  CAMCELF: ['far', 'building'],
  CAMCAREER: ['far', 'building'],
  CAMINVOLVE: ['fas', 'users'],
  CAMWELL: ['fas', 'user'],
  CAMVPVA: ['fas', 'fa-ban'],
  CAMATHREC: ['fas', 'fa-person-running'],
  ENGPLCTST: ['fas', 'fa-book-open'],
  ATHLETICS: ['fas', 'fa-person-running'],
  MATHPLCTST: ['fas', 'fa-book-open'],
  FORPLCTST: ['fas', 'fa-book-open'],
  SUMORNTATN: ['fas', 'users'],
  STUINVSUR: ['fas', 'fa-tablet'],
  CAMAPPHONORS: ['fas', 'user'],
  CAMSOAR: ['fas', 'users'],
  CAMTRSCPT: ['fas', 'file-alt'],
  CAMCONV: ['fas', 'fa-tablet'],
}
