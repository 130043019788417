import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import uuid from 'react-uuid'
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import useNewsSourceListDropDownStyles from './NewsSourceListStyles'

function NewsSourceListMobile({
  unsubscribedList,
  setUnsubscribedList,
  setModalClose,
  originalList,
  setEdited,
}) {
  const history = useHistory()
  const classes = useNewsSourceListDropDownStyles()
  const { sources } = useSelector((s) => s.news)

  const selectSource = (source) => {
    history.push({
      pathname: './NewsSourcePage',
      search: `?id=${source.sourceID}&source=${source.name}`,
    })
  }
  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false
    }
    const sortedArr1 = arr1.slice().sort()
    const sortedArr2 = arr2.slice().sort()
    return sortedArr1.every((element, index) => element === sortedArr2[index])
  }

  const updateList = (subscribe, source) => {
    let newList = []
    if (!subscribe) {
      newList = [...unsubscribedList, source.name]
    } else {
      newList = unsubscribedList.filter((name) => name !== source.name)
    }
    setUnsubscribedList(newList)
    if (arraysEqual(originalList, newList)) {
      setEdited(false)
    }
  }

  const itemClick = (source) => {
    selectSource(source)
    setModalClose()
  }
  const createNewsSourceListItems = () =>
    sources.map((source) => (
      <ListItem
        key={uuid()}
        button
        component="a"
        className={classes.listItemBody}
      >
        <ListItemText
          className={classes.listItemText}
          primary={source.name}
          onClick={() => itemClick(source)}
        />
        <ListItemIcon>
          <Checkbox
            edge="end"
            disabled={source.name === 'Rutgers Today'}
            checked={!unsubscribedList.includes(source.name)}
            onChange={() =>
              updateList(unsubscribedList.includes(source.name), source)
            }
          />
        </ListItemIcon>
      </ListItem>
    ))

  return (
    <List component="nav" className={classes.root}>
      {!_.isEmpty(sources) && createNewsSourceListItems()}
    </List>
  )
}

export default NewsSourceListMobile
