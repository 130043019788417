import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Slide,
  useTheme,
} from '@material-ui/core'
import ListAltIcon from '@material-ui/icons/ListAlt'

import {
  RutgersTabs,
  RutgersTab,
} from '../../../dashboard/DashboardComponents/Tabs/RutgersTabs'
import useNewsModalStyles from './NewsModalStyles'
import {
  setNews,
  setOpenNewsReorderDialog,
  updateUnsubscribedSources,
} from '../../newsSlice'
import {
  extractSectionsFromNewsList,
  setSectionsOrder,
  setUnsubscribedNewsSource,
} from '../../helpers'
import {
  TabPanel,
  a11yProps,
} from '../../../dashboard/DashboardComponents/TabPanel/TabPanel'
import NewsSourceList from '../NewsSourceList/NewsSourceListMobile'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import ReorderableNewsSection from '../ReorderNewsSections/ReorderableNewsSection'

function SourcesPanel({
  newsStatus,
  unsubscribedList,
  setUnsubscribedList,
  setEdited,
  setModalClose,
  originalList,
  edited,
}) {
  return (
    <>
      {newsStatus === 'fulfilled' && (
        <NewsSourceList
          unsubscribedList={unsubscribedList}
          setUnsubscribedList={(list) => {
            setUnsubscribedList(list)
            setEdited(true) // Setting edited to true when the list is updated
          }}
          setModalClose={setModalClose}
          originalList={originalList}
          setEdited={setEdited}
          edited={edited}
        />
      )}
    </>
  )
}

function CategoriesPanel({ newsSections, setNewsSections, setEdited }) {
  return (
    <>
      <ReorderableNewsSection
        newsSections={newsSections}
        setNewsSections={(sections) => {
          setNewsSections(sections)
          setEdited(true) // Setting edited to true when sections are updated
        }}
        setEdited={setEdited}
      />
    </>
  )
}

function NewsModal() {
  const dispatch = useDispatch()
  const classes = useNewsModalStyles()
  const openDialog = useSelector((s) => s.news.newsReorderDialogOpen)
  const isMobile = CheckIsMobile()
  const history = useHistory()

  const setOpen = () => {
    if (isMobile) {
      history.push({
        pathname: './NewsSourceListMobile',
      })
    } else if (openDialog) {
      dispatch(setOpenNewsReorderDialog(false))
    } else {
      dispatch(setOpenNewsReorderDialog(true))
    }
  }
  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="news source list menu"
        onClick={() => setOpen()}
      >
        <ListAltIcon className={classes.appBarIcon} />
      </IconButton>
      <NewsModalDialog
        openDialog={openDialog}
        setOpen={setOpen}
        dispatch={dispatch}
        classes={classes}
      />
    </>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} ref={ref} />
})

function NewsModalDialog({ openDialog, setOpen, dispatch, classes }) {
  const [newsSections, setNewsSections] = React.useState([])
  const [initialNewsSections, setInitialNewsSections] = React.useState([])
  const [edited, setEdited] = React.useState(false)
  const [tabValue, setTabValue] = React.useState(0)
  const theme = useTheme()
  const { news, newsStatus, unsubscribedSources } = useSelector((s) => s.news)
  const [unsubscribedList, setUnsubscribedList] = React.useState([])
  const [initialUnsubscribedList, setInitialUnsubscribedList] = React.useState(
    [],
  )

  React.useEffect(() => {
    if (openDialog) {
      const mapped = Object.entries(news)
        .sort(([, a], [, b]) => a.order - b.order)
        .map(([, value]) => value)
      setNewsSections(mapped)
      setInitialNewsSections(mapped)
      setUnsubscribedList(unsubscribedSources)
      setInitialUnsubscribedList(unsubscribedSources)
      // if (initialUnsubscribedList === unsubscribedList) {
      //   setEdited(false)
      // }
    }
  }, [news, openDialog, unsubscribedSources])

  const updateOrder = () => {
    const updatedNewsSections = newsSections.map((item, index) => ({
      ...item,
      order: index,
    }))

    setSectionsOrder(extractSectionsFromNewsList(updatedNewsSections))
    dispatch(setNews(updatedNewsSections))
    setEdited(false) // Resetting edited to false after updating
  }

  const updateList = () => {
    setUnsubscribedNewsSource(unsubscribedList)
    dispatch(updateUnsubscribedSources(unsubscribedList))
    setEdited(false) // Resetting edited to false after updating
    setOpen()
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleClose = () => {
    setNewsSections(initialNewsSections)
    setUnsubscribedList(initialUnsubscribedList)
    setEdited(false)
    setOpen()
  }

  const onSetClick = () => {
    updateOrder()
    updateList()
  }

  const tabs = ['Sources', 'Categories']
  console.log(edited)
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>Customize myNews</DialogTitle>
      <DialogContent dividers className={classes.dialog}>
        <RutgersTabs
          value={tabValue}
          onChange={handleChange}
          className={classes.rootTabContainer}
          aria-label="my-news tabs"
          variant="fullWidth"
        >
          {tabs.map((tab, index) => (
            <RutgersTab
              key={tab}
              label={tab}
              className={
                tabValue === index ? classes.activeTab : classes.inactiveTab
              }
              {...a11yProps(index)}
            />
          ))}
        </RutgersTabs>
        <TabPanel value={tabValue} index={0} dir={theme.direction}>
          <SourcesPanel
            newsStatus={newsStatus}
            unsubscribedList={unsubscribedList}
            setUnsubscribedList={setUnsubscribedList}
            setEdited={setEdited} // Passed setEdited to SourcesPanel
            setModalClose={() => handleClose()}
            originalList={initialUnsubscribedList}
            edited={edited}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1} dir={theme.direction}>
          <CategoriesPanel
            newsSections={newsSections}
            setNewsSections={setNewsSections}
            setEdited={setEdited} // Passed setEdited to CategoriesPanel
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between">
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>

          <Button
            onClick={onSetClick}
            variant="outlined"
            disabled={!edited}
            color="primary"
          >
            Set
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default NewsModal
