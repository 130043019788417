import * as React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import SearchIcon from '@material-ui/icons/Search'

import NewsSearchBar from '../../../features/news/components/NewsSearchBar/NewsSearchBar'
import { updateSearchBarContents } from '../../../features/news/newsSlice'
import NewsModal from '../../../features/news/components/NewsModal/NewsModal'
import { LogOutButton } from './DashboardLocalButtons'

export default function NewsLocalButtons(
  classes,
  history,
  isMobile,
  dispatch,
  search,
  userProfile,
) {
  return (
    <Grid item sm={4} md={4}>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid item>
          <NewsModal />
        </Grid>
        {!isMobile && (
          <Grid item className={classes.searchBarContainer} sm={7} xs={9}>
            <NewsSearchBar />
          </Grid>
        )}
        {isMobile && (
          <>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Rutgers Search"
                onClick={() =>
                  dispatch(
                    updateSearchBarContents({
                      ...search,
                      enableSearch: !search.enableSearch,
                      searchQuery: '',
                      articleList: [],
                    }),
                  )
                }
              >
                <SearchIcon className={classes.appBarIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Settings Menu"
                onClick={() => history.push('/settings')}
              >
                <SettingsIcon className={classes.appBarIcon} />
              </IconButton>
            </Grid>
          </>
        )}
        <LogOutButton
          isMobile={isMobile}
          dispatch={dispatch}
          userProfile={userProfile}
          classes={classes}
        />
      </Grid>
    </Grid>
  )
}
