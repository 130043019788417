import { makeStyles, createStyles } from '@material-ui/styles'

export const PolicyStyles = makeStyles((theme) =>
  createStyles({
    // MyFavorites.js

    footerButtonContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    visitPolicyLibButton: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      textAlign: 'center',

      width: '100%',
      height: '40px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    tabBackground: {
      overflowY: 'hidden',
      width: '100%',
      minHeight: 35,
      height: '35px',
      backgroundColor: theme.palette.backgroundColor.secondary,
    },
    modalTabs: {
      width: 335,
      height: 35,
      [theme.breakpoints.down(370)]: {
        width: 300,
      },
    },
    searchBar: {
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 5,
      height: '35px !important',
    },
    searchBarContainer: {
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
    },
    tabPanel0: {
      width: '100%',
      overflowY: 'auto',
    },
    tabPanel1: {
      overflowY: 'auto !important',
    },
    announcementList: {
      width: '100%',
      height: '343px',
      paddingTop: 10,
      paddingRight: 10,
      paddingLeft: 10,
    },
    gridItem: {
      width: '100%',
    },
    announcementCardBackground: {
      height: 'auto',
      width: '100%',
      marginTop: 10,
    },
    announcementBody: {
      paddingRight: 10,
      paddingLeft: 10,
      paddingBottom: 10,
    },
    titleButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'top',
      paddingLeft: 10,
    },
    titleButton: {
      maxWidthwidth: '30%',
      height: '35px',
      color: 'white',
      borderTopLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      background: theme.palette.base.red,
    },
  }),
)

export default PolicyStyles
