import * as React from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Chip from '@material-ui/core/Chip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../DashboardComponents/Footer/Footer'
import Widget from '../Widget'
import {
  fetchEnrollmentPathway,
  calculatePercentage,
  updatePendingTasks,
  updateCompleteTasks,
  sendEnrollmentPathwayTaskClick,
} from './enrollmentPathwaySlice'
import { enrollmentPathwayStyles } from './enrollmentPathwayStyles'
import Loading from '../helpers/Loading'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { logDashboardEvent } from '../../events'
// eslint-disable-next-line no-unused-vars
function EnrollmentPathway({ className, widgetId, type = 'required', title }) {
  const dispatch = useDispatch()
  const classes = enrollmentPathwayStyles()
  const { status, data, recommendedPercentage, requiredPercentage } =
    useSelector((s) => s.enrollmentPathway)
  React.useEffect(() => {
    if (type === 'required') {
      dispatch(fetchEnrollmentPathway(type))
    }
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content className={classes.content}>
        {renderDYKState(
          status,
          data,
          recommendedPercentage,
          requiredPercentage,
          type,
          widgetId,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderDYKState(
  status,
  data,
  recommendedPercentage,
  requiredPercentage,
  type,
  widgetId,
) {
  // TODO: Handle Proper Error States
  switch (status) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={data}
          recommendedPercentage={recommendedPercentage}
          requiredPercentage={requiredPercentage}
          enrollmentType={type}
          widgetId={widgetId}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Loaded
          widgetData={data}
          recommendedPercentage={0}
          requiredPercentage={0}
          enrollmentType={type}
          widgetId={widgetId}
        />
        // Will still load in the case of an error. Do not replace with <ErrorMessage />
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const token = localStorage.getItem('myrJwtToken')
  const footerTitle = 'Launch The Full List'
  const {
    recommendedPercentage,
    requiredPercentage,
    widgetData,
    enrollmentType,
    widgetId,
  } = props
  const {
    pendingRequiredTasks,
    completedRequiredTasks,
    pendingRecommendedTasks,
    completedRecommendedTasks,
    allTasks,
  } = widgetData
  const classes = enrollmentPathwayStyles()
  const dispatch = useDispatch()
  const { userProfile } = useSelector((s) => s.myProfile)
  const checkValidDate = (dateString) => {
    const dateObj = new Date(dateString)
    return dateObj instanceof Date && !Number.isNaN(dateObj.valueOf())
  }
  const handleCheckboxClick = (item) => {
    // || userProfile.proxyNetId
    if (item.chkboxDisabled === true) {
      return false
    }
    dispatch(sendEnrollmentPathwayTaskClick(item)).then((response) => {
      console.log('sendEnrollmentPathwayTaskClick response ', response)
      if (item.isComplete === true) {
        // handle moving to pendingTasks array
        dispatch(updatePendingTasks(item))
        dispatch(calculatePercentage(item))
      } else {
        // handle moving to completedTasks array
        dispatch(updateCompleteTasks(item))
        dispatch(calculatePercentage(item))
      }
    })
    return true
  }

  const handleEnrollmentTaskClick = (item) => {
    let link = `${process.env.REACT_APP_ENROLLMENTPATHWAY_HOST}/#/todos/${
      item.itemCode
    }?token=${token}&netID=${
      userProfile.netId
    }&proxyMode=${!!userProfile.proxyNetId}&rutgersEduExternalRcpId=${
      userProfile.rutgersEduExternalRcpId
    }`
    if (item?.itemVM?.link && item?.itemVM?.link !== '') {
      link = item?.itemVM?.link
    }
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, item.title, link)
    }
    window.open(link, '_blank')
  }

  const footerLinkClick = () => {
    const link = `${
      process.env.REACT_APP_ENROLLMENTPATHWAY_HOST
    }/#/todos/?token=${token}&netID=${
      userProfile.netId
    }&proxyMode=${!!userProfile.proxyNetId}&rutgersEduExternalRcpId=${
      userProfile.rutgersEduExternalRcpId
    }`
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        footerTitle,
        link,
      )
    }
    window.open(link, '_blank')
  }
  React.useEffect(() => {
    dispatch(
      calculatePercentage({
        type: enrollmentType === 'required' ? 'required' : 'recommended',
      }),
    )
  }, [])
  return (
    <>
      <Grid container direction="column" className={classes.mainGrid}>
        <Grid item>
          <Box className={classes.linearProgressContainer}>
            <LinearProgress
              classes={{
                root: classes.linearProgressBar,
                determinate: classes.linearBarBackgroundColor,
                bar1Determinate: classes.linearProgressBarOne,
              }}
              variant="determinate"
              value={
                enrollmentType === 'required'
                  ? requiredPercentage
                  : recommendedPercentage
              }
            />
            <Typography className={classes.linearProgressText}>
              {enrollmentType === 'required'
                ? requiredPercentage
                : recommendedPercentage}
              %
            </Typography>
          </Box>
        </Grid>
        <Grid className={classes.enrollmentTasksContainer}>
          {allTasks?.length === 0 ? (
            <Grid>
              <Box className={classes.enrollmentPathwayErrorContainer}>
                <Box className={classes.enrollmentPathwayContainerColor}>
                  <Typography variant="subtitle1">No Data Found</Typography>
                  <Typography variant="body1">
                    Admitted Students: A common reason for not having access to
                    this info is the $150 admissions enrollment fee was not
                    received. Please check your Application Portal to ensure you
                    submitted your enrollment fee and that it was
                    received/processed. Please allow 2-3 business days from the
                    time you paid your fee for the tasks to appear in this
                    section.
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    Current/Graduate Students: If you are currently enrolled and
                    taking classes at Rutgers, the My Enrollment Widgets are not
                    applicable. You can remove them from your dashboard by
                    clicking the hamburger icon and then reorder widgets button
                    top-left sidebar.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ) : (
            <>
              {/* All Pending Required Tasks */}
              {enrollmentType === 'required' ? (
                <>
                  <Typography className={classes.tasksHeader}>
                    Pending Tasks
                  </Typography>
                  {pendingRequiredTasks?.length > 0 ? (
                    pendingRequiredTasks?.map((item, index) => (
                      <Card
                        key={index} // eslint-disable-line react/no-array-index-key
                        className={classes.enrollmentTaskCard}
                        variant="outlined"
                      >
                        <CardContent
                          className={classes.enrollmentTaskCardContent}
                        >
                          <IconPicker
                            iconName={item.itemVM.code}
                            color="#cc0033"
                            size="4x"
                          />
                          <Typography
                            className={classes.enrollmentTaskTitle}
                            component="a"
                            onClick={() => handleEnrollmentTaskClick(item)}
                          >
                            {item.title}
                          </Typography>
                        </CardContent>
                        <CardActions
                          classes={{ root: classes.enrollmentCardActions }}
                        >
                          <FormControlLabel
                            label={
                              requiredPercentage === 0
                                ? 'Incomplete'
                                : 'Complete'
                            }
                            control={
                              <Checkbox
                                checked={item.isComplete}
                                disableRipple
                                disabled={item.chkboxDisabled ? true : null}
                                checkedIcon={
                                  <span
                                    className={clsx(
                                      classes.icon,
                                      classes.checkedIcon,
                                    )}
                                  />
                                }
                                icon={<span className={classes.icon} />}
                                onChange={() =>
                                  handleCheckboxClick({
                                    ...item,
                                    type: 'required',
                                  })
                                }
                                inputProps={{ 'aria-label': 'task list item' }}
                              />
                            }
                          />
                          {item.dueDateText ? (
                            <Chip
                              className={classes.enrollmentItemDateButton}
                              label={
                                checkValidDate(item.dueDateText)
                                  ? `Due: ${item.dueDateText}`
                                  : item.dueDateText
                              }
                            />
                          ) : null}
                        </CardActions>
                      </Card>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <IconPicker
                        iconName="check-circle"
                        color="#00b339"
                        size="5x"
                      />
                      <Typography>Tasks Complete</Typography>
                    </Box>
                  )}
                  {/* All completed required tasks */}
                  <Typography className={classes.tasksHeader}>
                    Completed Tasks
                  </Typography>
                  {completedRequiredTasks?.length > 0 ? (
                    completedRequiredTasks?.map((item, index) => (
                      <Card
                        key={index} // eslint-disable-line react/no-array-index-key
                        variant="outlined"
                        className={classes.enrollmentTaskCard}
                      >
                        <CardContent
                          className={classes.enrollmentTaskCardContent}
                        >
                          <IconPicker
                            iconName="check"
                            color="#00b339"
                            size="4x"
                          />
                          <Typography
                            className={classes.enrollmentTaskTitle}
                            component="a"
                            onClick={() => handleEnrollmentTaskClick(item)}
                          >
                            {item.title}
                          </Typography>
                        </CardContent>
                        <CardActions
                          classes={{ root: classes.enrollmentCardActions }}
                        >
                          <FormControlLabel
                            label={
                              requiredPercentage === 0
                                ? 'Incomplete'
                                : 'Complete'
                            }
                            control={
                              <Checkbox
                                checked={item.isComplete}
                                disableRipple
                                disabled={item.chkboxDisabled ? true : null}
                                checkedIcon={
                                  <span
                                    className={clsx(
                                      classes.icon,
                                      classes.checkedIcon,
                                    )}
                                  />
                                }
                                icon={<span className={classes.icon} />}
                                onChange={() =>
                                  handleCheckboxClick({
                                    ...item,
                                    type: 'required',
                                  })
                                }
                                inputProps={{ 'aria-label': 'task list item' }}
                              />
                            }
                          />
                          {item.dueDateText ? (
                            <Chip
                              className={classes.enrollmentItemDateButton}
                              label={
                                checkValidDate(item.dueDateText)
                                  ? `Due: ${item.dueDateText}`
                                  : item.dueDateText
                              }
                            />
                          ) : null}
                        </CardActions>
                      </Card>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <IconPicker
                        iconName="check-circle"
                        color="#00b339"
                        size="5x"
                      />
                      <Typography>Tasks Completed</Typography>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Typography className={classes.tasksHeader}>
                    Pending Tasks
                  </Typography>
                  {pendingRecommendedTasks?.length > 0 ? (
                    pendingRecommendedTasks?.map((item, index) => (
                      <Card
                        key={index} // eslint-disable-line react/no-array-index-key
                        className={classes.enrollmentTaskCard}
                        variant="outlined"
                      >
                        <CardContent
                          className={classes.enrollmentTaskCardContent}
                        >
                          <IconPicker
                            iconName={item.itemVM.code}
                            color="#cc0033"
                            size="4x"
                          />
                          <Typography
                            className={classes.enrollmentTaskTitle}
                            component="a"
                            onClick={() => handleEnrollmentTaskClick(item)}
                          >
                            {item.title}
                          </Typography>
                        </CardContent>
                        <CardActions
                          classes={{ root: classes.enrollmentCardActions }}
                        >
                          <FormControlLabel
                            label={
                              recommendedPercentage === 0
                                ? 'Incomplete'
                                : 'Complete'
                            }
                            control={
                              <Checkbox
                                disableRipple
                                checked={item.isComplete}
                                disabled={item.chkboxDisabled ? true : null}
                                checkedIcon={
                                  <span
                                    className={clsx(
                                      classes.icon,
                                      classes.checkedIcon,
                                    )}
                                  />
                                }
                                icon={<span className={classes.icon} />}
                                onChange={() =>
                                  handleCheckboxClick({
                                    ...item,
                                    type: 'recommended',
                                  })
                                }
                                inputProps={{ 'aria-label': 'task list item' }}
                              />
                            }
                          />
                          {item.dueDateText ? (
                            <Chip
                              className={classes.enrollmentItemDateButton}
                              label={
                                checkValidDate(item.dueDateText)
                                  ? `Due: ${item.dueDateText}`
                                  : item.dueDateText
                              }
                            />
                          ) : null}
                        </CardActions>
                      </Card>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <IconPicker
                        iconName="check-circle"
                        color="#00b339"
                        size="5x"
                      />
                      <Typography>Tasks Completed</Typography>
                    </Box>
                  )}
                  {/* All completed required tasks */}
                  <Typography className={classes.tasksHeader}>
                    Completed Tasks
                  </Typography>
                  {completedRecommendedTasks?.length > 0 ? (
                    completedRecommendedTasks?.map((item, index) => (
                      <Card
                        key={index} // eslint-disable-line react/no-array-index-key
                        className={classes.enrollmentTaskCard}
                        variant="outlined"
                      >
                        <CardContent
                          className={classes.enrollmentTaskCardContent}
                        >
                          <IconPicker
                            iconName={item.itemVM.code}
                            color="#cc0033"
                            size="4x"
                          />
                          <Typography
                            className={classes.enrollmentTaskTitle}
                            component="a"
                            onClick={() => handleEnrollmentTaskClick(item)}
                          >
                            {item.title}
                          </Typography>
                        </CardContent>
                        <CardActions
                          classes={{ root: classes.enrollmentCardActions }}
                        >
                          <FormControlLabel
                            label={
                              recommendedPercentage === 0
                                ? 'Incomplete'
                                : 'Complete'
                            }
                            control={
                              <Checkbox
                                checked={item.isComplete}
                                disableRipple
                                disabled={item.chkboxDisabled ? true : null}
                                checkedIcon={
                                  <span
                                    className={clsx(
                                      classes.icon,
                                      classes.checkedIcon,
                                    )}
                                  />
                                }
                                icon={<span className={classes.icon} />}
                                onChange={() =>
                                  handleCheckboxClick({
                                    ...item,
                                    type: 'recommended',
                                  })
                                }
                                inputProps={{ 'aria-label': 'task list item' }}
                              />
                            }
                          />
                          {item.dueDateText ? (
                            <Chip
                              className={classes.enrollmentItemDateButton}
                              label={
                                checkValidDate(item.dueDateText)
                                  ? `Due: ${item.dueDateText}`
                                  : item.dueDateText
                              }
                            />
                          ) : null}
                        </CardActions>
                      </Card>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <IconPicker
                        iconName="check-circle"
                        color="#00b339"
                        size="5x"
                      />
                      <Typography>Tasks Completed</Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
        <Grid item>
          <Footer>
            <Button
              component={Link}
              onClick={() => footerLinkClick()}
              target="_blank"
              fullWidth
            >
              <IconPicker
                iconName="external-link-alt"
                color="#ffffff"
                size="1x"
              />
              <span style={{ marginLeft: '5px' }}> {footerTitle} </span>
            </Button>
          </Footer>
        </Grid>
      </Grid>
    </>
  )
}

export default EnrollmentPathway
